import Webcam from "react-webcam";
import React, { useState, useRef, useCallback, useEffect } from "react";
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import { sha1 } from 'js-sha1';

const API_CLOUDINARY_PATH = 'cloudinary';
const API_CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/vie-de-dingue';
const CLOUDNARY_API_KEY = '519371866618269';
const CLOUDINARY_GET_TOKEN_URL = 'https://web.clarkup.com/get_token.php'

export const SLICE_SIZE = 20000000;



function WebcamVideo() {
  const videoConstraints = {
    facingMode: "user"
  };
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [successUpload, setSuccessUpload] = useState(false);

  // state to store time
  const [time, setTime] = useState(0);

  // state to check stopwatch running or not
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Hours calculation
  const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;

  async function getToken() {
    const response = await axios.get(CLOUDINARY_GET_TOKEN_URL);
    console.log("RESPONSE", response);
    return response.data;
  }

  async function uploadToClaudinary(video) {
    const token = await getToken();

    const blob = new Blob(video, {
      type: "video/webm"
    });

    if (blob.size <= 0) {
      return;
    }

    const data = new FormData();
    data.append('file', blob);
    data.append('api_key', CLOUDNARY_API_KEY);
    data.append('public_id', token.public_id);
    data.append('timestamp', token.timestamp.toString());
    data.append('signature', token.signature);

    axios.post(API_CLOUDINARY_URL + '/video/upload', data, {
      onUploadProgress: progressEvent => {
        console.log();
        setUploadStatus('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%');
      }
    }).then(res => {
      console.log(res);
      setRecordedChunks([]);
      setSuccessUpload(true);
      setUploadStatus(null);
    }).catch(err => {
      console.log(err);
      alert("Failed to upload");
      setUploadStatus(null);
    });
  }

  useEffect(() => {
    console.log("recordedChunks", recordedChunks);

    if (recordedChunks.length > 0) {
      uploadToClaudinary(recordedChunks);
    }

  }, [recordedChunks]);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    setTime(0);
    setIsRunning(true);

  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setIsRunning(false);

    if (webcamRef.current && webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach(track => track.stop());
    }
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  return (
      <>
        <Webcam audio={true} muted={true} ref={webcamRef} videoConstraints={videoConstraints}/>
        {uploadStatus && <p style={{color: 'white', margin: 5}}>{uploadStatus}</p>}
        {capturing && (
            <button onClick={handleStopCaptureClick}>Stop recording</button>
        )}
        {!successUpload && !capturing && (
            <button onClick={handleStartCaptureClick}>Start recording</button>
        )}
        {
            successUpload && (
                <div>
                  <h3 style={{color: 'white'}}>Video sent successfully, thanks !</h3>
                </div>
            )
        }
        <div className="stopwatch-container">
          <p className="stopwatch-time">
            {hours}:{minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}:
            {milliseconds.toString().padStart(2, "0")}
          </p>
        </div>
      </>
  );
};

export default WebcamVideo;