import "./App.css";
import WebcamVideo from "./WebcamVideo";

function App() {
    return (
        <div className="App">
            <WebcamVideo/>
        </div>
    );
}

export default App;